import React, { createContext, useContext, useEffect, useState } from "react";

const paddleContext = createContext<ReturnType<typeof useProvidePaddle>>(
  (null as unknown) as ReturnType<typeof useProvidePaddle>
);

export interface Price {
  gross: string;
  net: string;
  tax: string;
  tax_included: boolean;
}

export interface Price2 {
  gross: string;
  net: string;
  tax: string;
  tax_included: boolean;
}

export interface Subscription {
  trial_days: number;
  length: number;
  type: string;
}

export interface Recurring {
  price: Price2;
  subscription: Subscription;
}

export interface PriceResponse {
  price: Price;
  quantity: number;
  country: string;
  recurring: Recurring;
}

interface Paddle {
  Setup({ vendor }: { vendor: number }): void;
  Product: {
    Prices(id: number, cb: (data: PriceResponse) => void): void;
  };
  Checkout: {
    open({
      product,
      displayModeTheme,
      email,
      allowQuantity,
      disableLogout,
      passthrough,
      closeCallback,
      override,
    }: {
      product?: number;
      displayModeTheme: "dark";
      email?: string;
      allowQuantity?: boolean;
      disableLogout?: boolean;
      passthrough?: string;
      closeCallback?: () => void;
      successCallback?: (data: Record<string, string>) => void;
      override?: string;
    }): void;
  };
}

interface PaddleWindow extends Window {
  Paddle?: Paddle;
}

declare let window: PaddleWindow;

const injectPaddle = (onLoaded: () => void) => {
  if (typeof document == "undefined") return;
  const paddleScript = document.querySelector("script[id='paddle-script']");
  if (paddleScript) return;
  const script = document.createElement("script");
  script.id = "paddle-script";
  script.src = "https://cdn.paddle.com/paddle/paddle.js";
  script.onload = () => {
    onLoaded();
  };
  document.head.appendChild(script);
};

function useProvidePaddle() {
  const [paddle, setPaddle] = useState(typeof window !== "undefined" ? window.Paddle : undefined);

  useEffect(() => {
    injectPaddle(() => {
      if (window.Paddle) {
        window.Paddle.Setup({ vendor: 110379 });
      }
      setPaddle(window.Paddle);
    });
  }, []);

  return {
    paddle,
  };
}

export function PaddleProvider({ children }: { children: React.ReactNode }) {
  const value = useProvidePaddle();
  return <paddleContext.Provider value={value}>{children}</paddleContext.Provider>;
}

export const usePaddle = () => {
  return useContext(paddleContext);
};

export const useLocalizedPrice = (paddleId: number, fallback: string) => {
  const { paddle } = usePaddle();
  const [price, setPrice] = useState(fallback);

  useEffect(() => {
    if (!paddle) return;
    paddle.Product.Prices(paddleId, (data) => {
      setPrice(data.price.gross);
    });
  }, [paddle, paddleId]);

  return price;
};
