/** @jsxRuntime classic */
/*@jsx jsx*/
import { Text } from "@chakra-ui/react";
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";

export type GradientSelector = "red" | "blue" | "redWhitePink" | "sp" | null;

interface Props extends React.ComponentProps<typeof Text> {
  gradientSelector: GradientSelector;
}

export const SpecialHeading: FunctionComponent<Props> = ({
  gradientSelector,
  children,
  ...props
}) => {
  const gradients = {
    blue: "radial-gradient(circle at 50% 50%, #0032ff, #00c4ff 155%)",
    red: "radial-gradient(circle at 45% 50%, #ff3300, #ff006d 143%)",
    redWhitePink:
      "linear-gradient(to right, rgba(255, 51, 0, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 0, 109, 1) 100%)",
    sp: "radial-gradient(circle at 85% 100%, #ff006d, #ff3300 86%)",
  };
  const gradient = gradientSelector ? gradients[gradientSelector] : "";
  return (
    <Text
      alignSelf="flex-start"
      css={
        gradient
          ? {
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }
          : null
      }
      fontSize={["xl", "xl", "2xl", "2xl"]}
      fontWeight="900"
      backgroundImage={gradient}
      {...props}
    >
      {children}
    </Text>
  );
};
