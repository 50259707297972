import { Box, Icon, Link, Stack, StackProps, Text, useDisclosure } from "@chakra-ui/react";
import { SmallButton } from "@streamparty/app-ui/lib/components";
import { IoMdStar } from "@streamparty/app-ui/lib/icons";
import { SubscriptionTier } from "@streamparty/paddle-config";
import { format } from "date-fns";
import useTranslation from "next-translate/useTranslation";
import React, { FunctionComponent } from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

import { useGetAccountInformationQuery } from "../../generated/graphql";
import { useAuth } from "../../hooks/auth";
import { useConfirm } from "../../hooks/confirm";
import { usePaddle } from "../../hooks/paddle";
import { UserInformation } from "../../util/auth";
import { firebaseClient } from "../../util/firebase-client";
import { parseTimestamp } from "../../util/time";
import { SpecialHeading } from "../SpecialHeading";
import { SubscriptionManagementModal } from "./SubscriptionManagementModal";

interface Props extends StackProps {
  tier: SubscriptionTier;
  title: string;
  features: string[];
  costs: {
    monthly: string;
    quarterly?: string;
  };
  isPremium?: boolean;
  isBusiness?: boolean;
  onClick(): void;
  activeTier?: SubscriptionTier;
  isPublic?: boolean;
}

const update = async (tier: SubscriptionTier) => {
  const token = await firebaseClient.auth().currentUser?.getIdToken();
  if (!token) return;
  const response = await fetch("/api/paddle/update", {
    method: "POST",
    body: JSON.stringify({ tierId: tier }),
    headers: {
      Authentication: `Bearer ${token}`,
    },
  });

  return response.ok;
};

export const TierCard: FunctionComponent<Props> = ({
  tier,
  title,
  features,
  costs,
  isPremium,
  isBusiness,
  onClick,
  activeTier,
  isPublic = false,
  ...props
}) => {
  const { t } = useTranslation();
  const gradientSelector = isPremium ? "red" : isBusiness ? "blue" : null;
  const { userInfo } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { Dialog, getConfirmation } = useConfirm();

  const { paddle } = usePaddle();

  const { data, refetch } = useGetAccountInformationQuery({
    variables: {
      id: (userInfo as UserInformation)?.uid,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    },
    skip: !userInfo,
    pollInterval: 5000,
  });

  const cancelUrl = data?.User_by_pk?.UserSubscription?.cancelUrl;
  const cancelEffectiveDateString = data?.User_by_pk?.UserSubscription?.cancellationEffectiveDate;
  const cancelEffectiveDate = cancelEffectiveDateString
    ? parseTimestamp(cancelEffectiveDateString)
    : undefined;

  const successChangeTier = () => {
    // toast({
    //   title: "Plan updated",
    //   status: "success",
    //   duration: 9000,
    //   isClosable: true,
    // });
  };

  const changeToBusinessLite = async () => {
    const success = await update(SubscriptionTier.BUSINESS_LITE);
    if (success) {
      successChangeTier();
    }
    refetch();
  };

  const changeToPremium = async () => {
    const success = await update(SubscriptionTier.PREMIUM);
    if (success) {
      successChangeTier();
    }
    refetch();
  };

  const isActive = activeTier == tier;

  const getActionButton = () => {
    if (isPublic) {
      return (
        <SmallButton mx="auto" isWide={true} isPrimary onClick={onClick}>
          {t`auth:tiers.try`}
        </SmallButton>
      );
    }

    if (tier == SubscriptionTier.FREE) {
      if (cancelEffectiveDate) {
        return (
          <SmallButton mx="auto" isWide={true} isDisabled>
            {t(`auth:tiers.active_from`, { date: format(cancelEffectiveDate, "dd/MM/yyyy") })}
          </SmallButton>
        );
      }
      return (
        <SmallButton
          mx="auto"
          isWide={true}
          isDisabled={isActive}
          onClick={() => {
            if (!isActive && cancelUrl && paddle) {
              paddle.Checkout.open({ override: cancelUrl, displayModeTheme: "dark" });
            }
          }}
        >
          {isActive ? t`auth:tiers.active` : t`auth:tiers.downgrade`}
        </SmallButton>
      );
    }
    if (tier == SubscriptionTier.PREMIUM) {
      if (isActive && cancelEffectiveDate) {
        return (
          <SmallButton mx="auto" isWide={true} isDisabled>
            {t(`auth:tiers.active_until`, { date: format(cancelEffectiveDate, "dd/MM/yyyy") })}
          </SmallButton>
        );
      }
      if (cancelEffectiveDate) {
        return (
          <SmallButton mx="auto" isWide={true} isDisabled>
            {t`auth:tiers.buy_now`}
          </SmallButton>
        );
      }
      if (activeTier == SubscriptionTier.BUSINESS_LITE) {
        return (
          <SmallButton
            mx="auto"
            isWide={true}
            onClick={async () => {
              getConfirmation({
                title: t("auth:tiers.downgrade_to_title", { tier: t`auth:tiers.premium` }),
                text: t("auth:tiers.downgrade_to_text", { tier: t`auth:tiers.premium` }),
                acceptText: t`common:yes`,
                denyText: t`common:no`,
                acceptAction: changeToPremium,
              });
            }}
          >
            {t`auth:tiers.downgrade`}
          </SmallButton>
        );
      }
      return (
        <SmallButton
          mx="auto"
          isWide={true}
          isPrimary={!isActive}
          onClick={() => {
            if (isActive) {
              onOpen();
            } else {
              onClick();
            }
          }}
          leftIcon={isActive ? <IoMdStar /> : undefined}
        >
          {isActive ? t`auth:manage` : t`auth:tiers.buy_now`}
        </SmallButton>
      );
    }
    if (tier == SubscriptionTier.BUSINESS_LITE) {
      if (isActive && cancelEffectiveDate) {
        return (
          <SmallButton mx="auto" isWide={true} isDisabled>
            {t(`auth:tiers.active_until`, { date: format(cancelEffectiveDate, "dd/MM/yyyy") })}
          </SmallButton>
        );
      }
      if (cancelEffectiveDate) {
        return (
          <SmallButton mx="auto" isWide={true} isDisabled>
            {t`auth:tiers.buy_now`}
          </SmallButton>
        );
      }
      if (activeTier == SubscriptionTier.PREMIUM) {
        return (
          <>
            <SmallButton
              mx="auto"
              isWide={true}
              isPrimary
              onClick={async () => {
                getConfirmation({
                  title: t("auth:tiers.upgrade_to_title", { tier: t`auth:tiers.business` }),
                  text: t("auth:tiers.upgrade_to_text", { tier: t`auth:tiers.business` }),
                  acceptText: t`common:yes`,
                  denyText: t`common:no`,
                  acceptAction: changeToBusinessLite,
                });
              }}
            >
              {t`auth:tiers.upgrade`}
            </SmallButton>
          </>
        );
      }
      return (
        <SmallButton
          mx="auto"
          isWide={true}
          isPrimary={!isActive}
          onClick={() => {
            if (isActive) {
              onOpen();
            } else {
              onClick();
            }
          }}
          leftIcon={isActive ? <IoMdStar /> : undefined}
        >
          {isActive ? t`auth:manage` : t`auth:tiers.buy_now`}
        </SmallButton>
      );
    }
  };

  return (
    <>
      <Stack
        w="full"
        spacing={8}
        backgroundColor="cardBg"
        rounded="lg"
        shadow="lg"
        px={8}
        py={6}
        justify="space-between"
        {...props}
      >
        <Stack spacing={8}>
          <SpecialHeading gradientSelector={gradientSelector}>{title}</SpecialHeading>
          <Stack spacing={4}>
            {features.map((feature, idx: number) => (
              <Stack isInline key={idx} align="center">
                <Icon as={IoMdCheckmarkCircleOutline} color="green.500" fontSize="20px" mt={1} />
                <Text fontSize="lg" opacity={0.66}>
                  {feature}
                </Text>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack spacing={8}>
          <Stack spacing={0}>
            <Text fontSize="xl" fontWeight="bold">
              {costs.monthly}
            </Text>
            <Text fontSize="xl" opacity={0.5}>
              {costs.quarterly}
            </Text>
          </Stack>
          <Box alignSelf="center">{getActionButton()}</Box>
          <Link href="/terms" fontSize="xs">{t`auth:tiers.tos_note`}</Link>
        </Stack>
      </Stack>
      {isActive && tier != SubscriptionTier.FREE && (
        <SubscriptionManagementModal isOpen={isOpen} onClose={onClose} />
      )}
      <Dialog />
    </>
  );
};
