import { Stack, Text, useDisclosure } from "@chakra-ui/react";
import { SmallButton } from "@streamparty/app-ui/lib/components";
import dynamic from "next/dynamic";
import React, { useState } from "react";

const AlertModal = dynamic(() => import("../components/checkout/AlertModal"));

export interface ConfirmProps {
  title: string;
  text: string;
  denyText: string;
  acceptText: string;
  denyAction?: () => void;
  acceptAction: () => void;
}

export const useConfirm = () => {
  const [props, setProps] = useState<ConfirmProps | null>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const accept = () => {
    props?.acceptAction();
    onClose();
    setProps(null);
  };

  const deny = () => {
    props?.denyAction?.();
    onClose();
    setProps(null);
  };

  const getConfirmation = (props: ConfirmProps) => {
    setProps(props);
    onOpen();
  };

  const Dialog = () => {
    if (!props) return <></>;
    return (
      <AlertModal
        title={props.title}
        isOpen={isOpen}
        onClose={onClose}
        content={
          <Stack spacing={4} width="full">
            <Text textAlign="center">{props.text}</Text>
            <Stack direction={["column-reverse", "row"]} justify="space-between" width="full">
              <SmallButton isSimple w={["100%", "50%"]} onClick={deny}>
                {props.denyText}
              </SmallButton>
              <SmallButton isPrimary w={["100%", "50%"]} onClick={accept}>
                {props.acceptText}
              </SmallButton>
            </Stack>
          </Stack>
        }
      />
    );
  };

  return {
    Dialog,
    getConfirmation,
  };
};
