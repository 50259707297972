import { Modal, ModalContent, ModalOverlay, Stack, Text } from "@chakra-ui/react";
import { RoundIconButton, SmallButton } from "@streamparty/app-ui/lib/components";
import { IoIosClose } from "@streamparty/app-ui/lib/icons";
import useTranslation from "next-translate/useTranslation";
import React, { FunctionComponent } from "react";

import { useGetAccountInformationQuery } from "../../generated/graphql";
import { useAuth } from "../../hooks/auth";
import { usePaddle } from "../../hooks/paddle";
import { UserInformation } from "../../util/auth";

type SubscriptionManagementModalProps = {
  isOpen: boolean;
  onClose(): void;
};

export const SubscriptionManagementModal: FunctionComponent<SubscriptionManagementModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { userInfo } = useAuth();
  const { paddle } = usePaddle();
  const { t } = useTranslation();

  const { data } = useGetAccountInformationQuery({
    variables: {
      id: (userInfo as UserInformation).uid,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    },
    skip: !userInfo,
  });

  if (!data || !paddle) {
    return <></>;
  }

  const updateUrl = data?.User_by_pk?.UserSubscription?.updateUrl;
  const cancelUrl = data?.User_by_pk?.UserSubscription?.cancelUrl;

  if (!updateUrl || !cancelUrl) {
    return <></>;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent rounded="md" paddingY={8} backgroundColor="#201f1f" color="white">
        <RoundIconButton
          position="absolute"
          top="8px"
          right="12px"
          as={IoIosClose}
          aria-label="Close"
          onClick={() => onClose()}
        />
        <Stack align="center" spacing={8}>
          <Text fontWeight={800} fontSize={17}>
            {t`auth:tiers.manage`}
          </Text>
          <Stack>
            <SmallButton
              isPrimary
              onClick={() => {
                onClose();
                paddle.Checkout.open({ override: updateUrl, displayModeTheme: "dark" });
              }}
            >
              {t`auth:tiers.update_billing`}
            </SmallButton>
          </Stack>
          <Stack>
            <SmallButton
              isPrimary
              onClick={() => {
                onClose();
                paddle.Checkout.open({ override: cancelUrl, displayModeTheme: "dark" });
              }}
            >
              {t`auth:tiers.cancel`}
            </SmallButton>
          </Stack>
        </Stack>
      </ModalContent>
    </Modal>
  );
};
